import styled from "@emotion/styled"
import { alpha, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useEffect, useState } from "react"
import { Colors } from "../../styles/Colors"

export interface ICircularWordsProps {
  items: string[]
}

export const CircularWords = ({ items }: ICircularWordsProps): JSX.Element => {
  const theme = useTheme()
  const isExtraSmall = useMediaQuery(theme.breakpoints.down("xs"))
  const positionLoop = getPositionalLoop()
  const [offset, setOffset] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      if (offset >= items.length - 1) {
        setOffset(0)
      } else {
        setOffset(offset + 1)
      }
    }, 2000)

    return () => clearInterval(interval)
  }, [offset])

  function getPositionalLoop(): string[] {
    const loop = ["middle", "top"]
    for (let i = 1; i < items.length - 1; i++) {
      loop.push("hidden")
    }
    loop[items.length - 1] = "bottom"
    return loop
  }

  function getPosition(index: number): string {
    if (index + offset > items.length - 1) {
      return positionLoop[index + offset - items.length]
    }

    return positionLoop[index + offset]
  }

  return (
    <StyledCircularWords isExtraSmall={isExtraSmall}>
      {items.map((item, index) => {
        return (
          <RotatingItem key={item}>
            <Typography
              variant={isExtraSmall ? "h6" : "h4"}
              sx={{ width: "100%" }}
              noWrap
              className={getPosition(index)}
            >
              {item}
            </Typography>
          </RotatingItem>
        )
      })}
    </StyledCircularWords>
  )
}

const RotatingItem = styled.div`
  position: absolute;
  left: 0;
  width: fit-content;

  .top {
    animation-name: topMotion;
    -webkit-animation-name: topMotion;
    animation-delay: 0.5s;
    animation-duration: 2s;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    animation-direction: normal;
    color: ${alpha(Colors.white, 0.5)};
    transform: translateY(-200%);
    //animation-timing-function: ease-in-out;
  }

  .middle {
    animation-delay: 0.5s;
    animation-name: middleMotion;
    -webkit-animation-name: middleMotion;
    animation-duration: 2s;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    animation-direction: normal;
    color: ${alpha(Colors.white, 1)};
    transform: translateY(0);
    // animation-timing-function: ease-in-out;
  }

  .bottom {
    animation-delay: 0.5s;
    animation-name: bottomMotion;
    -webkit-animation-name: bottomMotion;
    animation-duration: 2s;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    animation-direction: normal;
    color: ${alpha(Colors.white, 0.5)};
    transform: translateY(200%);
    // animation-timing-function: ease-in-out;
  }

  .hidden {
    animation-delay: 0.5s;
    animation-name: hiddenMotion;
    -webkit-animation-name: hiddenMotion;
    animation-duration: 2s;
    -webkit-animation-duration: 2s;
    color: ${alpha(Colors.white, 0)};
    transform: translateY(200%);
    animation-timing-function: linear;
  }

  @keyframes hiddenMotion {
    0% {
      color: ${alpha(Colors.white, 0)};
      -webkit-text-fill-color: ${alpha(Colors.white, 0)};
    }
    100% {
      color: ${alpha(Colors.white, 0.5)};
      -webkit-text-fill-color: ${alpha(Colors.white, 0.5)};
    }
  }

  @keyframes bottomMotion {
    0% {
      color: ${alpha(Colors.white, 0.5)};
      -webkit-text-fill-color: ${alpha(Colors.white, 0.5)};
      transform: translateY(200%);
      -webkit-transform: translateY(200%);
      -webkit-transform-style: flat;
    }
    100% {
      color: ${Colors.white};
      -webkit-text-fill-color: ${Colors.white};
      transform: translateY(0);
      -webkit-transform: translateY(0);
      -webkit-transform-style: flat;
    }
  }

  @keyframes topMotion {
    0% {
      color: ${alpha(Colors.white, 0.5)};
      -webkit-text-fill-color: ${alpha(Colors.white, 0.5)};
      transform: translateY(-200%);
      -webkit-transform: translateY(-200%);
      -webkit-transform-style: flat;
    }
    50% {
      color: ${alpha(Colors.white, 0.1)};
      -webkit-text-fill-color: ${alpha(Colors.white, 0.1)};
    }
    100% {
      color: ${alpha(Colors.white, 0)};
      -webkit-text-fill-color: ${alpha(Colors.white, 0)};
      transform: translateY(200%);
      -webkit-transform: translateY(200%);
      -webkit-transform-style: flat;
    }
  }

  @keyframes middleMotion {
    0% {
      color: ${Colors.white};
      -webkit-text-fill-color: ${Colors.white};
      transform: translateY(0px);
      -webkit-transform: translateY(0px);
      -webkit-transform-style: flat;
    }
    100% {
      color: ${alpha(Colors.white, 0.5)};
      -webkit-text-fill-color: ${alpha(Colors.white, 0.5)};
      transform: translateY(-200%);
      -webkit-transform: translateY(-200%);
      -webkit-transform-style: flat;
    }
  }
`

const StyledCircularWords = styled.div<{ isExtraSmall: boolean }>`
  height: 200px;
  width: ${(props) => (props.isExtraSmall ? "150px" : "200px")};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`
