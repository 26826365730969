/**
 *
 * @param value : string The string to be sanitized.
 * @param failRegexExpressions : RegExp[] A list of regular expressions that if matched mean the string has failed
 * validation. Use this to only allow certain character sets. EG: [/[^a-zA-Z0-9]+/] would mean if any character
 * not in a-z, A-Z, or 0-9 then the string is invalid and should not be used.
 * @param maxLength : number The max length allowed for this string
 */
export function validateUrlParam(value: string | string[] | undefined, failRegexExpressions: RegExp[], maxLength = 100): string {
    let parsedValue: string
    if (Array.isArray(value)) {
        parsedValue = value.join("")
    } else if (value === undefined) {
        parsedValue = ""
    } else {
        parsedValue = value
    }
    if (parsedValue.length > maxLength) {
        return ""
    } else {
        for (const expression of failRegexExpressions) {
            parsedValue = parsedValue.replace(expression, "")
        }
        return parsedValue
    }
}
