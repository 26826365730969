import styled from "@emotion/styled"
import {
  alpha,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import {
  ArrowBack,
  ArrowForward,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material"
import React, { Dispatch, useState, useEffect, useContext } from "react"
import { ILoginResponse, login, post } from "../../utils/requests"
import { ActionTypes, UserActions, UserContext } from "../../contexts/user"
import { useRouter } from "next/router"
import BlossmLogoWhite from "../assets/BlossmLogoWhite.svg"
import { ArgJSONMap } from "../../utils/argjsonmap"
import {
  parseLoginData,
  parseRegisterData,
  parseWaffles,
} from "../../utils/parsers"
import { UsernameSetup } from "../Organisms/UsernameSetup"
import { updateUserContext } from "../../utils/account"
import { PopUp } from "./PopUp"
import { theme } from "../../../styles/Theme"
import { SiteNotificationsActions } from "../../contexts/siteNotificationContext"
import { ActionTypes as SiteNotificationActionTypes } from "../../contexts/siteNotificationContext"
import SummaryBacking from "../assets/BlossmSummaryBacking.svg"
import { Colors } from "../../styles/Colors"
import {
  PrimaryButton,
  SecondaryButton,
  SecondaryLoadingButton,
  SecondaryTextButton,
  WhiteTextButton,
} from "./Buttons"
import { CircularWords } from "./CircularWords"
import Link from "next/link"
import {
  passwordRequirementsMessage,
  TFA_APP_DEVICE,
  TFA_PHONE_DEVICE,
} from "../../utils/constants"
import ReferralCodeSetup from "../Organisms/ReferralCodeSetup"
import { GetCookie } from "../../utils/cookies"

import {
  WafflesActions,
  ActionTypes as waffleActionTypes,
} from "../../contexts/waffles"
import { trackLoginEvent, trackSignUpEvent } from "../../utils/eventTracking"
import { Turnstile, TurnstileInstance } from "@marsidev/react-turnstile"
import { BlossmImage } from "./BlossmImage"
import { validateUrlParam } from "../../utils/paramValidation"

export const enum Step {
  Launch,
  SignIn,
  SignUp,
  EnterEmail,
  EnterUsername,
  EnterPassword,
  ForgotPassword,
  PasswordResetLinkSent,
  WhatIsBlossm,
  ConfirmTfaCode,
  EnterReferralCode = 8,
}

export interface ILaunchProps {
  dispatch: Dispatch<UserActions>
  siteNotificationDispatch: Dispatch<SiteNotificationsActions>
  wafflesDispatch: Dispatch<WafflesActions>
  close?: () => void
  startingStep?: Step
}

type IValidateSignupData = {
  email?: string
  password?: string
  username?: string
  referralCode?: string
}

// eslint-disable-next-line complexity
export const Launch = ({
  dispatch,
  siteNotificationDispatch,
  wafflesDispatch,
  close,
  startingStep,
}: ILaunchProps): JSX.Element => {
  const [step, setStep] = useState<Step>(
    startingStep ? startingStep : Step.Launch
  )
  const [email, setEmail] = useState("")
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [tfaCode, setTfaCode] = useState("")
  const [tfaType, setTfaType] = useState<string>("")
  const [last4PhoneDigits, setLast4PhoneDigits] = useState("")
  const [referralCode, setReferralCode] = useState<string | undefined>(
    undefined
  )
  const [errorModalMsg, setErrorModalMsg] = useState("")
  const [emailInputErrorMsg, setEmailInputErrorMsg] = useState("")
  const [passwordInputErrorMsg, setPasswordInputErrorMsg] = useState("")
  const [usernameInputErrorMsg, setUsernameInputErrorMsg] = useState("")
  const [tfaCodeInputErrorMsg, setTfaCodeInputErrorMsg] = useState("")
  const [authenticationErrorMsg, setAuthenticationErrorMsg] = useState("")
  const [referralCodeErrorMsg, setReferralCodeErrorMsg] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const router = useRouter()
  const theme = useTheme()
  const isExtraSmall = useMediaQuery(theme.breakpoints.down("xs"))
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"))
  const throttleHttpErrorStatus = 429
  const [turnstileToken, setTurnstileToken] = useState("")
  const { context } = useContext(UserContext)
  const [loginButtonDisabled, setLoginButtonDisabled] = useState<boolean>(false)
  const turnstileRef = React.useRef<TurnstileInstance | undefined>(null)

  const resetCredErrors = (): void => {
    setEmailInputErrorMsg("")
    setPasswordInputErrorMsg("")
  }

  const handleStepChange = (): void => {
    setAuthenticationErrorMsg("")
    resetCredErrors()
    setPassword("")
  }

  useEffect(() => {
    if (step === Step.ForgotPassword) {
      router.push("/password-reset/email")
    }
  }, [step])

  //use effect on mount
  useEffect(() => {
    const ref = GetCookie("ref")
    if (ref) {
      setReferralCode(ref)
    }
  }, [])

  const parseLoginFailure = (data: ArgJSONMap, status: number): void => {
    if (status === throttleHttpErrorStatus) {
      setEmailInputErrorMsg(
        "You've attempted login too many times recently. Please wait a couple minutes and try again."
      )
    } else {
      const emailErrors: string[] = data.getListOfType<string>("email")
      const authError = data.getString("detail")
      const authFieldErrors: string[] =
        data.getListOfType<string>("non_field_errors")
      authFieldErrors.push(authError)
      setAuthenticationErrorMsg(authFieldErrors.join(", "))
      setEmailInputErrorMsg([...emailErrors, ...authFieldErrors].join(", "))

      const passwordErrors: string[] = data.getListOfType<string>("password")
      setPasswordInputErrorMsg(passwordErrors.join(", "))

      const tfaCodeErrors: string[] = data.getListOfType<string>("tfa_code")
      setTfaCodeInputErrorMsg([...tfaCodeErrors].join(", "))
    }
  }

  const handleFormValidation = (nextStep: Step): void => {
    const requestData: { [key in keyof IValidateSignupData]: string } = {}
    if (email !== "") {
      requestData["email"] = email
    }
    if (username !== "") {
      requestData["username"] = username
    }
    if (password !== "") {
      requestData["password"] = password
    }
    if (referralCode) {
      requestData["referralCode"] = referralCode
    }
    if (turnstileToken === "") {
      return
    }
    if (Object.keys(requestData).length > 0) {
      post("auth/sign-up/validate/", requestData)
        .then(() => {
          resetCredErrors()
          setUsernameInputErrorMsg("")
          setStep(nextStep)
        })
        .catch((error) => {
          const data = ArgJSONMap.fromParsedJson(error.response.data)
          let emailErrors: string[] = []
          let passwordErrors: string[] = []
          let usernameErrors: string[] = []
          let referralCodeErrors: string[] = []
          let otherErrors: string[] = []
          if ("email" in error.response.data) {
            emailErrors = data.getListOfType<string>("email", false)
            setEmailInputErrorMsg(emailErrors.join(", "))
          } else {
            setEmailInputErrorMsg("")
          }

          if ("password" in error.response.data) {
            passwordErrors = data.getListOfType<string>("password", false)
            setPasswordInputErrorMsg(passwordErrors.join(", "))
          } else {
            setPasswordInputErrorMsg("")
          }

          if ("username" in error.response.data) {
            if (error.response.status === 429) {
              setUsernameInputErrorMsg(
                "You've attempted to create too many profiles lately. Please try again later."
              )
            } else {
              usernameErrors = data.getListOfType<string>("username", false)
              setUsernameInputErrorMsg(usernameErrors.join(", "))
            }
          } else {
            setUsernameInputErrorMsg("")
          }

          if ("referral_code" in error.response.data) {
            referralCodeErrors = data.getListOfType<string>(
              "referral_code",
              false
            )
            setReferralCodeErrorMsg(referralCodeErrors.join(", "))
          }
          if ("non_field_errors" in error.response.data) {
            otherErrors = data.getListOfType<string>("non_field_errors", false)
          }

          if (otherErrors.length > 0) {
            const otherErrorsString = otherErrors.join(", ")
            setErrorModalMsg(otherErrorsString)
          }
        })
    }
  }

  const handleSignIn = (): void => {
    setUsernameInputErrorMsg("")
    setPasswordInputErrorMsg("")
    setLoginButtonDisabled(true)

    const getRedirectURL = (redirectParam: string): string => {
      const redirectURL = new URL(redirectParam, window.location.origin)
      if (redirectURL.hostname === window.location.hostname) {
        return encodeURIComponent(
          validateUrlParam(
            redirectURL.toString(),
            [/[^a-zA-Z0-9/\-=?:.]/g],
            200
          )
        ).replace(/%(3A|3D|3F|2F|2E)/g, decodeURIComponent)
      } else {
        return "/"
      }
    }

    login<ILoginResponse>({
      email: email,
      password: password,
      // eslint-disable-next-line camelcase
      tfa_code: tfaCode,
      captcha: turnstileToken,
    })
      .then((response) => {
        const data = ArgJSONMap.fromParsedJson(response.data)

        if (data.getBoolean("tfa_required")) {
          setStep(Step.ConfirmTfaCode)
          setTfaType(data.getString("tfa_type"))
          setLast4PhoneDigits(data.getString("last_4_phone_digits"))
          setLoginButtonDisabled(false)
          return
        }

        trackLoginEvent()
        dispatch({
          type: ActionTypes.Login,
          payload: parseLoginData(data),
        })

        siteNotificationDispatch({
          type: SiteNotificationActionTypes.UpdateSiteNotificationCount,
          payload: {
            newSiteNotificationCount: data.getNumber(
              "unread_notification_count"
            ),
          },
        })

        wafflesDispatch({
          type: waffleActionTypes.PopulateWaffles,
          payload: parseWaffles(
            ArgJSONMap.fromParsedJson(JSON.parse(data.getString("waffles")))
          ),
        })
        setErrorModalMsg("")
        setPassword("")
        setTimeout(() => {
          setLoginButtonDisabled(false)
          const landingPage = { value: "/" }
          const queryString = window.location.search
          const params = new URLSearchParams(queryString)
          const redirectParam = params.get("redirect")
          if (redirectParam) {
            landingPage.value = getRedirectURL(redirectParam)
          }
          router.push(landingPage.value)
        }, 500)
        if (close) {
          close()
        }
      })
      .catch((error) => {
        if (typeof turnstileRef.current !== "undefined") {
          turnstileRef.current?.reset()
        }
        parseLoginFailure(
          ArgJSONMap.fromParsedJson(error.response.data),
          error.response.status
        )
        setLoginButtonDisabled(false)
      })
  }

  type RegisterData = {
    email: string
    username: string
    password: string
    referralCode?: string
  }

  const handleSetAPassword = (): void => {
    const data: RegisterData = {
      email,
      username,
      password,
      referralCode: referralCode || undefined,
    }

    if (referralCode) {
      data["referralCode"] = referralCode
    }
    post("auth/register/", data)
      .then(async (response) => {
        const data = ArgJSONMap.fromParsedJson(response.data)
        const parsedData = parseRegisterData(data)
        dispatch({
          type: ActionTypes.Login,
          payload: parsedData,
        })
        trackSignUpEvent()
        updateUserContext(dispatch, siteNotificationDispatch, parsedData.uuid)
        router.push("/onboarding/")
      })
      .catch((error) => {
        const data = ArgJSONMap.fromParsedJson(error.response.data)
        let emailErrors: string[] = []
        let passwordErrors: string[] = []
        let usernameErrors: string[] = []
        let referralCodeErrors: string[] = []
        let otherErrors: string[] = []
        if ("email" in error.response.data) {
          emailErrors = data.getListOfType<string>("email", false)
          setEmailInputErrorMsg(emailErrors.join(", "))
        } else {
          setEmailInputErrorMsg("")
        }

        if ("password" in error.response.data) {
          passwordErrors = data.getListOfType<string>("password", false)
          setPasswordInputErrorMsg(passwordErrors.join(", "))
        } else {
          setPasswordInputErrorMsg("")
        }

        if ("username" in error.response.data) {
          usernameErrors = data.getListOfType<string>("username", false)
          setUsernameInputErrorMsg(usernameErrors.join(", "))
        } else {
          setUsernameInputErrorMsg("")
        }
        if ("referral_code" in error.response.data) {
          referralCodeErrors = data.getListOfType<string>(
            "referral_code",
            false
          )
          setReferralCodeErrorMsg(referralCodeErrors.join(", "))
        } else {
          setReferralCodeErrorMsg("")
        }

        if ("non_field_errors" in error.response.data) {
          otherErrors = data.getListOfType<string>("non_field_errors", false)
        }

        if ("detail" in error.response.data) {
          otherErrors.push(data.getString("detail"))
        }

        if (emailErrors.length > 0) {
          setStep(Step.EnterEmail)
        } else if (usernameErrors.length > 0) {
          setStep(Step.EnterUsername)
        } else if (referralCodeErrors.length > 0) {
          setStep(Step.EnterReferralCode)
        }

        if (otherErrors.length > 0) {
          const otherErrorsString = otherErrors.join(", ")
          setErrorModalMsg(otherErrorsString)
        }
      })
  }

  function handleBack(): void {
    switch (step) {
      case Step.Launch:
        if (close) {
          close()
        } else {
          router.back()
        }
        break
      case Step.SignIn:
        if (startingStep && close) {
          close()
          break
        }
        setStep(Step.Launch)
        break
      case Step.SignUp:
        if (startingStep && close) {
          close()
          break
        }
        setStep(Step.Launch)
        break
      case Step.EnterEmail:
        if (startingStep && close) {
          close()
        }
        //undo this once we have more than email signup
        setStep(Step.Launch)
        setEmail("")
        // setStep(Step.SignUp)
        break
      //this will be uncommented when we go back to more than just email for sign up
      case Step.EnterUsername:
        setStep(Step.EnterEmail)
        setUsername("")
        break
      case Step.EnterReferralCode:
        setStep(Step.EnterUsername)
        setReferralCode("")
        setReferralCodeErrorMsg("")

        break
      case Step.EnterPassword:
        setStep(Step.EnterReferralCode)
        setPassword("")
        setReferralCodeErrorMsg("")
        break
      default:
        break
    }
  }

  return (
    <StyledLauncher
      step={step}
      theme={theme}
      isExtraSmall={isExtraSmall}
      isMobile={isMobile}
    >
      <div className={"launch-back"}>
        {/*Note this should not appear on first step (step !== Step.Launch) but while we have invite codes we need this*/}
        <IconButton onClick={() => handleBack()}>
          <ArrowBack sx={{ color: Colors.white }} />
        </IconButton>
      </div>
      <div className="Logo-Header">
        <BlossmImage src={BlossmLogoWhite} alt="Blossm Logo" />
      </div>
      {step === Step.Launch && (
        <Typography
          variant={"h6"}
          sx={{
            color: Colors.white,
            width: "100%",
            textAlign: "center",
            mb: 2,
          }}
        >
          Promote yourself.
        </Typography>
      )}
      <StyledContent>
        {step === Step.Launch && (
          <StyledLaunch>
            <SummaryContainer>
              <LaunchLabelContainer>
                <Typography
                  variant={isExtraSmall ? "h6" : "h4"}
                  sx={{ color: Colors.white }}
                  noWrap
                >
                  Grow your
                </Typography>
              </LaunchLabelContainer>
              <CircularWords
                items={["Fanbase", "Business", "Income", "Reach"]}
              />
            </SummaryContainer>
            <div className={"action-btn-container"}>
              <SecondaryButton
                sx={{ mb: 1 }}
                onClick={() => setStep(Step.EnterEmail)}
              >
                Sign Up
              </SecondaryButton>
              <PrimaryButton
                variant={"outlined"}
                sx={{
                  color: Colors.white,
                  mb: isExtraSmall ? 1 : 4,
                  borderColor: Colors.white,
                }}
                onClick={() => setStep(Step.SignIn)}
              >
                Log In
              </PrimaryButton>
              <WhiteTextButton
                variant={"text"}
                sx={{
                  justifyContent: "space-between",
                  mb: isExtraSmall ? 1 : 4,
                }}
                endIcon={<ArrowForward />}
                onClick={() => router.push("/featured")}
              >
                What is Blossm?
              </WhiteTextButton>
            </div>
            <Link
              href={"/terms"}
              passHref
              target="_blank"
              rel="noopener noreferrer"
              className={"terms-link"}
            >
              Read the Terms & Conditions
            </Link>
          </StyledLaunch>
        )}
        {step === Step.SignUp && (
          <StyledSignUp>
            <div className="alts">
              <PrimaryButton onClick={() => setStep(Step.EnterEmail)}>
                Sign up with Email
              </PrimaryButton>
              {/*<FaceBook >Sign up with Facebook</FaceBook>*/}
              {/*<Google >Sign up with Google</Google>*/}
              <SecondaryTextButton
                variant={"text"}
                onClick={() => setStep(Step.SignIn)}
              >
                Have an Account? Log In Instead
              </SecondaryTextButton>
              <div className="dialog-terms-conditions">
                <Link
                  href={"/terms"}
                  passHref
                  target="_blank"
                  rel="noopener noreferrer"
                  className={"terms-link"}
                >
                  Terms
                </Link>
              </div>
            </div>
          </StyledSignUp>
        )}
        {step === Step.SignIn && (
          <StyledSignUp>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                handleSignIn()
              }}
            >
              <div className="entry">
                <TextField
                  sx={{
                    input: {
                      color: Colors.white,
                    },
                    "& label.Mui-focused": {
                      color: Colors.white,
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: Colors.white,
                    },
                    "& .Mui-error": {
                      color: theme.palette.error.light,
                    },
                    "& .Mui-error:after": {
                      borderBottomColor: theme.palette.error.light,
                    },
                    "& .MuiFormHelperText-root": {
                      color: theme.palette.error.light,
                      fontSize: 15,
                    },
                    "& label": {
                      color: Colors.white,
                    },
                    "& .MuiInput-root:hover:not(.Mui-disabled):before": {
                      borderBottom: "1px solid " + Colors.white,
                    },
                    ".MuiInput-root:before": {
                      borderBottom: "1px solid " + alpha(Colors.white, 0.5),
                    },
                    ".MuiInput-root:before:hover": {
                      borderBottom: "1px solid " + alpha(Colors.white, 0.5),
                    },
                  }}
                  InputLabelProps={{ required: false }}
                  required
                  variant="standard"
                  label="Email"
                  error={emailInputErrorMsg !== ""}
                  autoFocus={true}
                  helperText={emailInputErrorMsg}
                  autoCapitalize={"none"}
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLTextAreaElement | HTMLInputElement
                    >
                  ) => {
                    setEmail(event.currentTarget.value.toLowerCase())
                  }}
                />
                <TextField
                  sx={{
                    input: {
                      color: Colors.white,
                    },
                    "& label.Mui-focused": {
                      color: Colors.white,
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: Colors.white,
                    },
                    "& .Mui-error": {
                      color: theme.palette.error.light,
                    },
                    "& .Mui-error:after": {
                      borderBottomColor: theme.palette.error.light,
                    },
                    "& .MuiFormHelperText-root": {
                      color: theme.palette.error.light,
                      fontSize: 15,
                    },
                    "& label": {
                      color: Colors.white,
                    },
                    "& .MuiInput-root:hover:not(.Mui-disabled):before": {
                      borderBottom: "1px solid " + Colors.white,
                    },
                    ".MuiInput-root:before": {
                      borderBottom: "1px solid " + alpha(Colors.white, 0.5),
                    },
                    ".MuiInput-root:before:hover": {
                      borderBottom: "1px solid " + alpha(Colors.white, 0.5),
                    },
                  }}
                  InputLabelProps={{ required: false }}
                  required
                  variant="standard"
                  type={showPassword ? "text" : "password"}
                  label="Password"
                  error={
                    passwordInputErrorMsg !== "" ||
                    authenticationErrorMsg !== ""
                  }
                  helperText={passwordInputErrorMsg}
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLTextAreaElement | HTMLInputElement
                    >
                  ) => {
                    setPassword(event.currentTarget.value)
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            setShowPassword(!showPassword)
                          }}
                          onMouseDown={(event) => {
                            event.preventDefault()
                          }}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOff sx={{ color: Colors.white }} />
                          ) : (
                            <Visibility sx={{ color: Colors.white }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              {context.captcha && (
                <Turnstile
                  className="cloudflare-turnstile sign-in-turnstile"
                  siteKey={context.captcha}
                  onSuccess={setTurnstileToken}
                  ref={turnstileRef}
                />
              )}
              <div className="buttons">
                <SecondaryButton type="submit">Log in</SecondaryButton>
                <SecondaryTextButton
                  variant={"text"}
                  onClick={() => setStep(Step.ForgotPassword)}
                  sx={{ mt: 1 }}
                >
                  Reset my password
                </SecondaryTextButton>
                <SecondaryTextButton
                  variant="text"
                  onClick={() => {
                    handleStepChange()
                    setStep(Step.EnterEmail)
                  }}
                  sx={{ mt: 1 }}
                >
                  Don't have an account? Sign up
                </SecondaryTextButton>
              </div>
              {/* // Todo: get SSO Working */}
              {/*<div className="alts">*/}
              {/*    <FaceBook >Sign in with Facebook</FaceBook>*/}
              {/*    <Google >Sign in with Google</Google>*/}
              {/*    <Email >Sign in with Email</Email>*/}
              {/*</div>*/}
            </form>
          </StyledSignUp>
        )}
        {step === Step.EnterEmail && (
          <StyledEmailSignUp>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                handleFormValidation(Step.EnterUsername)
              }}
            >
              <Typography
                className={"email-header"}
                color={Colors.white}
                fontWeight={"bold"}
                variant={"h4"}
              >
                let's get started
              </Typography>
              <Typography color={Colors.white} variant={"subtitle2"}>
                Enter your email to create your account
              </Typography>
              <TextField
                sx={{
                  input: {
                    color: Colors.white,
                  },
                  "& label.Mui-focused": {
                    color: Colors.white,
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: Colors.white,
                  },
                  "& .Mui-error": {
                    color: theme.palette.error.light,
                  },
                  "& .Mui-error:after": {
                    borderBottomColor: theme.palette.error.light,
                  },
                  "& .MuiFormHelperText-root": {
                    color: theme.palette.error.light,
                    fontSize: 15,
                  },
                  "& label": {
                    color: Colors.white,
                  },
                  "& .MuiInput-root:hover:not(.Mui-disabled):before": {
                    borderBottom: "1px solid " + Colors.white,
                  },
                  ".MuiInput-root:before": {
                    borderBottom: "1px solid " + alpha(Colors.white, 0.5),
                  },
                  ".MuiInput-root:before:hover": {
                    borderBottom: "1px solid " + alpha(Colors.white, 0.5),
                  },
                }}
                InputLabelProps={{ required: false }}
                className={"email-entry"}
                required
                variant="standard"
                label="Email"
                error={emailInputErrorMsg !== ""}
                helperText={emailInputErrorMsg}
                autoFocus={true}
                value={email}
                autoCapitalize={"none"}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) => {
                  setEmail(event.currentTarget.value.toLowerCase())
                }}
              />
              {context.captcha && (
                <Turnstile
                  className="cloudflare-turnstile sign-up-turnstile"
                  siteKey={context.captcha}
                  onSuccess={setTurnstileToken}
                />
              )}
              <SecondaryButton
                sx={{ mb: 1 }}
                onClick={() => handleFormValidation(Step.EnterUsername)}
                type="submit"
                className="continue-button"
                disabled={turnstileToken === ""}
              >
                {" "}
                Continue
              </SecondaryButton>
              <Typography
                color={Colors.white}
                sx={{
                  paddingTop: "1px",
                  display: "flex",
                  justifyContent: "center",
                }}
                variant={"body1"}
              >
                By signing up you agree to the &nbsp;
                <Link
                  href={"/terms"}
                  passHref
                  target="_blank"
                  rel="noopener noreferrer"
                  className={"terms-link"}
                >
                  Terms & Conditions
                </Link>
              </Typography>
              <SecondaryTextButton
                onClick={() => {
                  handleStepChange()
                  setStep(Step.SignIn)
                }}
                className={"email-sign-in-bailout"}
                variant={"text"}
              >
                Have an Account? Log In Instead
              </SecondaryTextButton>
            </form>
          </StyledEmailSignUp>
        )}
        {step === Step.EnterUsername && (
          <UsernameSetup
            darkMode={true}
            usernameInputErrorMsg={usernameInputErrorMsg}
            setUsername={setUsername}
            signInInsteadOnClick={() => setStep(Step.SignIn)}
            continueOnClick={() => handleFormValidation(Step.EnterReferralCode)}
            username={username}
          />
        )}
        {step === Step.EnterReferralCode && (
          <ReferralCodeSetup
            referralCode={referralCode}
            setReferralCode={setReferralCode}
            referralCodeErrorMsg={referralCodeErrorMsg}
            continueOnClick={() => handleFormValidation(Step.EnterPassword)}
            signInInsteadOnClick={() => setStep(Step.SignIn)}
          />
        )}
        {step === Step.EnterPassword && (
          <StyledEmailSignUp>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                handleSetAPassword()
              }}
            >
              <Typography
                className={"email-header"}
                color={Colors.white}
                fontWeight={"bold"}
                variant={"h4"}
              >
                set a password
              </Typography>
              <Typography color={Colors.white} variant={"subtitle2"}>
                {passwordRequirementsMessage}
              </Typography>
              <TextField
                className={"email-entry"}
                required
                InputLabelProps={{ required: false }}
                variant="standard"
                label="Password"
                type={showPassword ? "text" : "password"}
                error={passwordInputErrorMsg !== ""}
                helperText={passwordInputErrorMsg}
                autoFocus={true}
                value={password}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) => {
                  setPassword(event.currentTarget.value)
                }}
                sx={{
                  input: {
                    color: Colors.white,
                  },
                  "& label.Mui-focused": {
                    color: Colors.white,
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: Colors.white,
                  },
                  "& .Mui-error": {
                    color: theme.palette.error.light,
                  },
                  "& .Mui-error:after": {
                    borderBottomColor: theme.palette.error.light,
                  },
                  "& .MuiFormHelperText-root": {
                    color: theme.palette.error.light,
                    fontSize: 15,
                  },
                  "& label": {
                    color: Colors.white,
                  },
                  "& .MuiInput-root:hover:not(.Mui-disabled):before": {
                    borderBottom: "1px solid " + Colors.white,
                  },
                  ".MuiInput-root:before": {
                    borderBottom: "1px solid " + alpha(Colors.white, 0.5),
                  },
                  ".MuiInput-root:before:hover": {
                    borderBottom: "1px solid " + alpha(Colors.white, 0.5),
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setShowPassword(!showPassword)
                        }}
                        onMouseDown={(event) => {
                          event.preventDefault()
                        }}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOff sx={{ color: Colors.white }} />
                        ) : (
                          <Visibility sx={{ color: Colors.white }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <SecondaryButton className="continue-button" type="submit">
                {" "}
                Continue
              </SecondaryButton>
              <Typography
                variant={"body1"}
                sx={{
                  color: Colors.white,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                By signing up you agree to the &nbsp;
                <Link
                  href={"/terms"}
                  passHref
                  target="_blank"
                  rel="noopener noreferrer"
                  className={"terms-link"}
                >
                  Terms & Conditions
                </Link>
              </Typography>
              <SecondaryTextButton
                onClick={() => {
                  handleStepChange()
                  setStep(Step.SignIn)
                }}
                variant={"text"}
              >
                Have an Account? Log In Instead
              </SecondaryTextButton>
            </form>
          </StyledEmailSignUp>
        )}
        {step === Step.ConfirmTfaCode && (
          <StyledConfirmTfaCode>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                handleSignIn()
              }}
            >
              <Typography
                className={"email-header"}
                color={Colors.white}
                fontWeight={"bold"}
                variant={"h4"}
              >
                enter confirmation code
              </Typography>
              <Typography color={Colors.white} variant={"subtitle2"}>
                {tfaType === TFA_PHONE_DEVICE &&
                  `A 6-digit code was sent to your phone number ending in ${last4PhoneDigits}. 
                  Please enter it below to log in.`}
                {tfaType === TFA_APP_DEVICE &&
                  "Enter the 6 digit code from your authenticator app to finish logging in."}
              </Typography>
              <TextField
                className={"code-entry"}
                required
                InputLabelProps={{ required: false }}
                variant="standard"
                label="Code"
                error={tfaCodeInputErrorMsg !== ""}
                helperText={tfaCodeInputErrorMsg}
                autoFocus={true}
                value={tfaCode}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) => {
                  setTfaCode(event.currentTarget.value)
                }}
                sx={{
                  input: {
                    color: Colors.white,
                  },
                  "& label.Mui-focused": {
                    color: Colors.white,
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: Colors.white,
                  },
                  "& .Mui-error": {
                    color: theme.palette.error.light,
                  },
                  "& .Mui-error:after": {
                    borderBottomColor: theme.palette.error.light,
                  },
                  "& .MuiFormHelperText-root": {
                    color: theme.palette.error.light,
                    fontSize: 15,
                  },
                  "& label": {
                    color: Colors.white,
                  },
                  "& .MuiInput-root:hover:not(.Mui-disabled):before": {
                    borderBottom: "1px solid " + Colors.white,
                  },
                  ".MuiInput-root:before": {
                    borderBottom: "1px solid " + alpha(Colors.white, 0.5),
                  },
                  ".MuiInput-root:before:hover": {
                    borderBottom: "1px solid " + alpha(Colors.white, 0.5),
                  },
                }}
              />
              <SecondaryLoadingButton
                loading={loginButtonDisabled}
                className="continue-button"
                type="submit"
              >
                Log In
              </SecondaryLoadingButton>
            </form>
          </StyledConfirmTfaCode>
        )}
      </StyledContent>
      <PopUp
        open={errorModalMsg.length > 0}
        handleToggle={() => setErrorModalMsg("")}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Log In failed</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorModalMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <PrimaryButton onClick={() => setErrorModalMsg("")}>Ok</PrimaryButton>
        </DialogActions>
      </PopUp>
    </StyledLauncher>
  )
}

export const StyledWhatIsBlossm = styled.div<{
  isExtraSmall: boolean
  isDesktop: boolean
}>`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-image: url(${SummaryBacking.src});
  background-size: 90% auto;
  background-repeat: no-repeat;

  .slide-content {
    display: flex;
    flex-direction: column;
    padding-top: ${(props) =>
      props.isDesktop ? "0" : props.isExtraSmall ? "16px" : "45px"};
    padding-bottom: ${(props) =>
      props.isDesktop ? "16px" : props.isExtraSmall ? "16px" : "45px"};
    width: 100%;

    .slide-button-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`

const LaunchLabelContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`

const SummaryContainer = styled.div`
  width: 100%;
  height: fit-content;
  align-items: center;
  background-image: url(${SummaryBacking.src});
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  gap: 16px;
  margin-top: 60px;
  padding-bottom: 60px;
`

const StyledEmailSignUp = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .cloudflare-turnstile {
    margin-bottom: 8vh;
  }

  .cloudflare-turnstile.sign-up-turnstile {
    margin-top: calc(8vh - 32px);
  }

  .email-header {
    padding-bottom: 8px;
  }

  .email-entry {
    margin-top: 20px;
    margin-bottom: 32px;
  }
`

const StyledLauncher = styled.div<{
  step: Step
  theme: Theme
  isExtraSmall: boolean
  isMobile: boolean
}>`
  height: 100%;
  width: 100%;
  display: flex;
  padding: 16px;
  padding-bottom: ${(props) => (props.isExtraSmall ? "0" : "24px")};
  flex-direction: column;
  background: ${(props) => props.theme.palette.primary.main};

  .terms-link {
    color: ${(props) => props.theme.palette.secondary.light};
    display: flex;
    justify-content: center;
  }

  .launch-back {
    margin-top: 24px;
    height: 24px;
    padding-left: 4px;
    -webkit-tap-highlight-color: transparent;
  }

  .Logo-Header {
    display: flex;
    min-height: 85px;
    overflow: visible;
    justify-content: center;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  .login-step-sign-up-instead-button {
    margin-top: 16px;
  }

  .reset-password-button {
    margin-top: 32px;
  }

  .continue-button {
    margin-bottom: 16px;
  }

  .dialog-terms-conditions {
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    margin-bottom: 24px;

    .terms-link {
      cursor: pointer;
      color: ${(props) => props.theme.palette.secondary.light};
    }

    .MuiButton-root {
      line-height: normal;
    }

    .MuiTypography-root {
      font-size: 12px !important;
    }

    .button-terms-conditions-link {
      color: ${theme.palette.secondary.light};
    }

    .button-terms-conditions-main {
      color: ${theme.palette.primary.main};
      font-size: 16px;
    }
  }

  .cloudflare-turnstile {
    margin-left: auto;
    margin-right: auto;
  }
`

const StyledLaunch = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-bottom: 24px;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`

const StyledSignUp = styled.div`
  width: 100%;

  .entry {
    display: flex;
    flex-direction: column;
    gap: 1vh;
  }

  .alts,
  .buttons {
    display: flex;
    flex-direction: column;
    padding-top: 8vh;
    padding-right: 5px;
    padding-left: 5px;
    gap: 0;
  }

  .sign-up-button {
    padding-top: 16px;
  }

  .forgot-password-subtitle {
    margin-bottom: 40px;
  }

  .cloudflare-turnstile.sign-in-turnstile {
    margin-top: 8vh;
  }
`

const StyledContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;

  .action-btn-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
`

const StyledConfirmTfaCode = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .email-header {
    padding-bottom: 8px;
  }

  .code-entry {
    display: flex;
    flex-direction: column;
    gap: 1vh;
    margin-top: 20px;
    margin-bottom: 32px;
  }
`
