import { alpha, TextField, Theme, Typography, useTheme } from "@mui/material"
import React, { Dispatch, SetStateAction } from "react"
import styled from "@emotion/styled"
import { theme } from "../../../styles/Theme"
import {
  PrimaryButton,
  SecondaryButton,
  SecondaryTextButton,
} from "../Atoms/Buttons"
import { Colors } from "../../styles/Colors"
import Link from "next/link"

export interface IUsernameSetupProps {
  usernameInputErrorMsg: string
  setUsername: Dispatch<SetStateAction<string>>
  signInInsteadOnClick?: () => void
  continueOnClick: () => void | Dispatch<SetStateAction<string>>
  username: string
  darkMode: boolean
}

export const UsernameSetup = ({
  usernameInputErrorMsg,
  setUsername,
  signInInsteadOnClick,
  continueOnClick,
  username,
  darkMode,
}: IUsernameSetupProps): JSX.Element => {
  const theme = useTheme()
  return (
    <StyledUsernameSetup theme={theme}>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          continueOnClick()
        }}
      >
        <Typography
          className={"email-header"}
          color={darkMode ? Colors.white : theme.palette.text.primary}
          fontWeight={"bold"}
          variant={"h4"}
        >
          give yourself a username
        </Typography>
        <Typography
          color={darkMode ? Colors.white : theme.palette.text.primary}
          variant={"subtitle2"}
        >
          Your @username can be changed at any time.
        </Typography>
        <TextField
          InputLabelProps={{ required: false }}
          className={"email-entry"}
          required
          variant="standard"
          label="Username"
          error={usernameInputErrorMsg !== ""}
          helperText={usernameInputErrorMsg}
          autoFocus={true}
          InputProps={{
            startAdornment: (
              <p
                style={{
                  color: darkMode ? Colors.white : theme.palette.text.primary,
                }}
              >
                @
              </p>
            ),
          }}
          value={username}
          autoCapitalize={"none"}
          onChange={(
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            setUsername(event.currentTarget.value.toLowerCase())
          }}
          sx={{
            input: {
              color: darkMode ? Colors.white : theme.palette.text.primary,
            },
            "& label.Mui-focused": {
              color: darkMode ? Colors.white : theme.palette.text.primary,
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: darkMode
                ? Colors.white
                : theme.palette.text.primary,
            },
            "& .Mui-error": {
              color: theme.palette.error.light,
            },
            "& .Mui-error:after": {
              borderBottomColor: theme.palette.error.light,
            },
            "& .MuiFormHelperText-root": {
              color: theme.palette.error.light,
              fontSize: 15,
            },
            "& label": {
              color: darkMode ? Colors.white : theme.palette.text.primary,
            },
            "& .MuiInput-root:hover:not(.Mui-disabled):before": {
              borderBottom:
                "1px solid " + darkMode
                  ? Colors.white
                  : theme.palette.text.primary,
            },
            ".MuiInput-root:before": {
              borderBottom:
                "1px solid " +
                alpha(
                  darkMode ? Colors.white : theme.palette.text.primary,
                  0.5
                ),
            },
            ".MuiInput-root:before:hover": {
              borderBottom:
                "1px solid " +
                alpha(
                  darkMode ? Colors.white : theme.palette.text.primary,
                  0.5
                ),
            },
          }}
        />
        {darkMode ? (
          <SecondaryButton className="continue-button" type="submit">
            Continue
          </SecondaryButton>
        ) : (
          <PrimaryButton className="continue-button" type="submit">
            Continue
          </PrimaryButton>
        )}
        <Typography
          variant={"body1"}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          By signing up you agree to the &nbsp;
          <Link
            href={"/terms"}
            passHref
            target="_blank"
            rel="noopener noreferrer"
            className={"terms-link"}
            style={{
              color: `${
                darkMode
                  ? theme.palette.primary.contrastText
                  : theme.palette.primary.main
              }`,
            }}
          >
            Terms & Conditions
          </Link>
        </Typography>
        {signInInsteadOnClick && (
          <SecondaryTextButton
            variant={"outlined"}
            onClick={signInInsteadOnClick}
            className={"email-sign-in-bailout"}
          >
            Have an Account? Log In Instead
          </SecondaryTextButton>
        )}
      </form>
    </StyledUsernameSetup>
  )
}

const StyledUsernameSetup = styled.div<{ theme: Theme }>`
  display: flex;
  flex-direction: column;
  padding-left: 17px;
  padding-right: 17px;
  width: 100%;

  form {
    display: flex;
    flex-direction: column;
  }

  .email-header {
    padding-bottom: 8px;
  }

  .email-entry {
    margin-top: 20px;
    margin-bottom: 32px;
  }

  .email-sign-in-bailout {
    margin-bottom: 32px;
  }

  .continue-button {
    margin-bottom: 32px;
  }

  .dialog-terms-conditions {
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    margin-bottom: 24px;
    
    .MuiButton-root {
      line-height: normal;
    }

    .MuiTypography-root {
      font-size: 12px !important;
    }

    .terms-conditions {
      font-size: 12px;
      padding: 0px;
      color: ${theme.palette.secondary.light};
    }

    .terms-link {
      color: ${(props) => props.theme.palette.secondary.light};
      display: flex;
      justify-content: center;
    }
`
