import React, { Dispatch, SetStateAction, useEffect } from "react"
import { alpha, Stack, TextField, Typography, useTheme } from "@mui/material"
import {
  InverseButton,
  SecondaryButton,
  SecondaryTextButton,
} from "../Atoms/Buttons"
import styled from "@emotion/styled"
import Link from "next/link"

export interface IReferralCodeSetupProps {
  referralCodeErrorMsg?: string
  continueOnClick: () => void | Dispatch<SetStateAction<string>>
  referralCode: string | undefined
  setReferralCode: Dispatch<SetStateAction<string | undefined>>
  signInInsteadOnClick?: () => void
}

const StyledForm = styled.form`
  width: 100%;
`

const ReferralCodeSetup = ({
  referralCodeErrorMsg,
  continueOnClick,
  referralCode,
  setReferralCode,
  signInInsteadOnClick,
}: IReferralCodeSetupProps): JSX.Element => {
  const theme = useTheme()
  const [skipClicked, setSkipClicked] = React.useState(false)
  useEffect(() => {
    if (!skipClicked) {
      return
    }

    setSkipClicked(false) // reset the state for future clicks
    continueOnClick()
  }, [skipClicked])

  return (
    <StyledForm
      onSubmit={(e) => {
        e.preventDefault()
        continueOnClick()
      }}
    >
      <Stack
        direction={"column"}
        spacing={3}
        sx={{
          width: "100%",
        }}
      >
        <Typography
          color={theme.palette.primary.contrastText}
          fontWeight={"bold"}
          variant={"h4"}
        >
          do you have a referral code?
        </Typography>
        <Typography
          color={theme.palette.primary.contrastText}
          variant={"subtitle2"}
        >
          If you don’t have a code, select “Skip” below.
        </Typography>
        <TextField
          InputLabelProps={{ required: false }}
          variant="standard"
          label="Referral Code"
          error={referralCodeErrorMsg !== ""}
          helperText={referralCodeErrorMsg}
          autoFocus={true}
          value={referralCode}
          autoCapitalize={"none"}
          onChange={(
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            setReferralCode(
              event.currentTarget.value.toLowerCase().replace(/\s/g, "")
            )
          }}
          sx={{
            input: {
              color: theme.palette.primary.contrastText,
            },
            "& label.Mui-focused": {
              color: theme.palette.primary.contrastText,
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: theme.palette.primary.contrastText,
            },
            "& .Mui-error": {
              color: theme.palette.error.light,
            },
            "& .Mui-error:after": {
              borderBottomColor: theme.palette.error.light,
            },
            "& .MuiFormHelperText-root": {
              color: theme.palette.error.light,
              fontSize: 15,
            },
            "& label": {
              color: theme.palette.primary.contrastText,
            },
            "& .MuiInput-root:hover:not(.Mui-disabled):before": {
              borderBottom: "1px solid " + theme.palette.primary.contrastText,
            },
            ".MuiInput-root:before": {
              borderBottom:
                "1px solid " + alpha(theme.palette.primary.contrastText, 0.5),
            },
            ".MuiInput-root:before:hover": {
              borderBottom:
                "1px solid " + alpha(theme.palette.primary.contrastText, 0.5),
            },
          }}
        />
        {
          <Stack spacing={1}>
            <SecondaryButton type="submit" size={"large"}>
              Continue
            </SecondaryButton>
            <InverseButton
              size={"large"}
              variant={"outlined"}
              sx={{
                color: theme.palette.background.default,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.background.default,
              }}
              onClick={() => {
                setReferralCode("")
                setSkipClicked(true)
              }}
            >
              Skip
            </InverseButton>
            <Typography
              variant={"body1"}
              sx={{ display: "flex", justifyContent: "center" }}
              color={theme.palette.primary.contrastText}
            >
              By signing up you agree to the &nbsp;
              <Link
                href={"/terms"}
                passHref
                target="_blank"
                rel="noopener noreferrer"
                className={"terms-link"}
              >
                Terms & Conditions
              </Link>
            </Typography>
            {signInInsteadOnClick && (
              <SecondaryTextButton
                variant={"outlined"}
                onClick={signInInsteadOnClick}
                className={"email-sign-in-bailout"}
              >
                Have an Account? Log In Instead
              </SecondaryTextButton>
            )}
          </Stack>
        }
      </Stack>
    </StyledForm>
  )
}

export default ReferralCodeSetup
